import React, { Suspense } from "react";
import ReactLoading from "react-loading";
import { LOGO_IMG } from "../../config/constant/imgConstant";
import { THEME_DARK_BLUE } from "../../config/constant/projectConstant";

export const LazyLoader = ({ children }) => {
  return <Suspense fallback={<FullScreenLoader />}>{children}</Suspense>;
};

export const FullScreenLoader = ({ children }) => {
  return (
    <div className="centerElem">
      <div className="centerElemOverlay" />
      <div className="visibleBox">
        <img src={LOGO_IMG} className="tw-w-60" alt="logo" />
        <ReactLoading
          type={"spinningBubbles"}
          color={THEME_DARK_BLUE}
          height={"20%"}
          width={"20%"}
        />
      </div>
    </div>
  );
};

export const FullSectionLoader = ({ children }) => {
  return (
    <div className="flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="flex flex-1 bg-light" />
      <ReactLoading
        type={"bars"}
        color={THEME_DARK_BLUE}
        height={"7%"}
        width={"7%"}
      />
    </div>
  );
};

export const ComponentLoader = ({ children }) => {
  return (
    <div className="centerElem">
      <div className="centerElemOverlay" />
      <div className="visibleBox">
        <p>Querying data...</p>
        <ReactLoading
          type={"spinningBubbles"}
          color={THEME_DARK_BLUE}
          height={"20%"}
          width={"20%"}
        />
      </div>
    </div>
  );
};
